import React from 'react'

import logo from '../../../images/logo-inter-pf-avulsa.png'

import BaixeOApp from 'src/assets/images/qr-code-open-account-pf-modal.png'

import MSG from '../../msgAccount.json'

import { Container, Image } from '../../style'

const FilledStep = () => {
  return (
    <Container className='d-flex align-items-center filled'>
      <div className='col-12 text-center'>
        <div className='d-flex justify-content-center w-100 pb-md-5'>
          <Image src={logo} alt='Logo Inter' />
        </div>
        <h3 className='fs-20 lh-24 fw-600 text-grayscale--500'>
          Baixe o app e vá em <br /> “Abrir uma conta gratuita”
        </h3>
        <p className='fs-16 lh-20 text-grayscale--400 mb-5'>
          {MSG.filled}
        </p>
        <div className='col-12'>
          <img src={BaixeOApp} alt='Baixe o App Inter' />
        </div>
      </div>
    </Container>
  )
}

export default FilledStep
