import React, { MouseEvent } from 'react'

import useWidth from 'src/hooks/window/useWidth'

import BaixeOApp from 'src/assets/images/qr-code-open-account-pf-modal.png'
import logo from '../../../images/logo-inter-pf-avulsa.png'
import sucesso from '../../../images/sucesso.png'

import { Container, Image } from '../../style'

const UnderAgeStep = () => {
  const WIDTH_MD = 1024
  const windowWidth = useWidth(200)

  const asUnderAge = (evt: MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault()
    if (windowWidth < WIDTH_MD) {
      window.location.href = 'https://intergo.app/7820e737'
    } else {
      window.location.href = '/pra-voce/conta-digital/conta-kids/'
    }
  }

  return (
    <Container className='d-flex align-items-center under-age'>
      <div className='col-12 text-center'>
        <div className='d-flex justify-content-center w-100 pb-md-5'>
          <Image src={logo} alt='Logo Inter' />
        </div>
        <h3 className='fs-16 lh-20 text-grayscale--400 fw-400 fw-md-600'>
          Vimos que você tem menos de 18 anos.
        </h3>
        <p className='fs-20 lh-23 fs-md-24 lh-md-28 text-grayscale--500 mb-4 fw-600'>
          <span className='d-block'>
            Conheça nossa
            <span
              className='text-orange--extra cursor-pointer'
              onClick={asUnderAge}
            > Conta Kids
            </span>,
          </span>
          que também é completa, digital e gratuita.
        </p>
        <div className='col-12 pb-2 mx-auto d-lg-none text-center'>
          <img src={sucesso} alt='Sucesso' />
        </div>
        <div className='col-12 pb-2 mx-auto d-none d-lg-block text-center'>
          <img src={BaixeOApp} alt='Baixe o App Inter' />
        </div>
        <div className='col-12 d-none d-lg-block px-0'>
          <hr />
          <p className='fs-14 lh-17 text-left'>
            Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e
            clicar em “Abrir conta completa e gratuita”. É rápido e fácil!
          </p>
          <p className='fs-14 lh-17 text-left'>
            De acordo com nossa Política de Privacidade, não armazenamos dados de menores de 18 anos
            sem o consentimento dos responsáveis.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default UnderAgeStep
